@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

/* * {
  box-sizing: border-box;
} */

.border-box {
  box-sizing: border-box;
}

/* bulk drop down keyborad style */
.autocomplete-search-result {
  padding: 0.75rem 1rem;
  cursor: pointer;
}

.autocomplete-search-result.selected {
  background-color: #ccdff4; /* Background color when selected */
}

.autocomplete-search-result:hover,
.autocomplete-search-result:focus,
.autocomplete-search-result:active {
  background-color: #f9fafc; /* Background color on hover, focus, and active */
}

/* invoice table */
.myTable {
  border: 1px solid black;
  border-collapse: collapse;
  width: 794px;
  height: 100%;
}
.myTable th,
.myTable td {
  border: 1px solid black;
  padding: 8px;
}

/* year picker */
.year-picker-wrapper {
  display: inline-block;
  position: relative;
}

.year-picker-wrapper .react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.year-picker-wrapper .react-datepicker-wrapper input {
  display: none; /* Hide the default input */
}

.year-picker-wrapper .react-datepicker-popper {
  position: absolute;
  z-index: 1;
  left: 0;
}

.year-picker-wrapper .react-datepicker-popper.open {
  display: block;
}

.calander {
  width: 100px;
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
  gap: 5px;
  color: #24292e;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .calander {
    width: 94vw;
  }
}
.ordercontainer {
  /* max-width: 380px; */
  /* margin: 200px auto; */
  /* overflow: hidden; */
  /* height: fit-content; */
}

.printer-top {
  z-index: 1;
  border: 6px solid #666666;
  height: 6px;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  background: #333333;
}

.printer-bottom {
  z-index: 0;
  border: 6px solid #666666;
  height: 6px;
  border-top: 0;
  border-radius: 0 0 6px 6px;
  background: #333333;
}

.paper-container {
  position: relative;
  overflow: hidden;
  height: 600px;
}

.paper {
  background: #ffffff;
  font-family: "Poppins", sans-serif;
  /* height: 447px; */
  /* position: absolute; */
  z-index: 2;
  margin: 0 12px;
  margin-top: -12px;
}

.main-contents {
  margin: 0 12px;
  padding: 24px;
}

/* .jagged-edge {
  position: relative;
  height: 40px;
  width: 100%;
  margin-top: -1px;
  background-color: #24292E;
  display: block;

} */

.jagged-edge {
  content: "";
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(
      45deg,
      transparent 33.333%,
      #ed0505 33.333%,
      #7d0303 66.667%,
      transparent 66.667%
    ),
    linear-gradient(
      -45deg,
      transparent 33.333%,
      #480303 33.333%,
      #160000 66.667%,
      transparent 66.667%
    );
  background-size: 16px 40px;
  background-position: 0 -20px;
}

.success-icon {
  text-align: center;
  font-size: 48px;
  height: 72px;
  background: #359d00;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  margin: 16px auto;
  color: #fff;
}

.success-title {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: #666;
  font-weight: bold;
  margin-bottom: 16px;
}

.success-description {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  line-height: 21px;
  color: #999;
  text-align: center;
  margin-bottom: 24px;
}

.order-details {
  text-align: center;
  color: #333;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.order-number-label {
  font-size: 18px;
  margin-bottom: 8px;
}

.order-number {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  line-height: 48px;
  font-size: 48px;
  padding: 8px 0;
  margin-bottom: 24px;
}

.complement {
  font-size: 18px;
  margin-bottom: 8px;
  color: #32a852;
}

.err {
  /* margin-top: 100px; */
  font-size: 50px;
  font-weight: bolder;
  color: #ccc;
  text-align: center;
}
/* .thanks-msg{
  font-size: ;
} */
.custom-datepicker .ant-picker-input input::placeholder {
  color: #6b7280; /* Change this to your desired placeholder color */
}
.custom-datepicker .ant-picker-input input {
  height: 2rem;
}
